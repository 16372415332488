import ApiInstance from "./ApiInstance";

const getPath = (path) => {
  return `home/${path}`;
};
class NewsUpdatesApis extends ApiInstance {
  getCanadaNews = async () => {
    try {
      const data = await this.getData(getPath("canadaNews"));
      return data;
    } catch (error) {
      return error;
    }
  };

  getUkNews = async () => {
    try {
      const data = await this.getData(getPath("ukNews"));
      return data;
    } catch (error) {
      return error;
    }
  };


  getUsaNews = async () => {
    try {
      const data = await this.getData(getPath("usaNews"));
      return data;
    } catch (error) {
      return error;
    }
  };


//   getCanadaNews = async () => {
//     try {
//       const data = await this.getData(getPath("canadaNews"));
//       return data;
//     } catch (error) {
//       return error;
//     }
//   };



}

export default new NewsUpdatesApis();
