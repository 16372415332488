import { useState, useEffect } from "react";
import "./InputStyle/style.css";
import { Box, Divider, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import _ from "lodash";

export const DropdownNavComponent = ({
  menuName = "Dropdown",
  list = [],
  isSticky = false,
}) => {
  const [isHoverMenu, setIsHoverMenu] = useState(false);

  const handleHover = () => {
    setIsHoverMenu(true);
  };
  return (
    <Box
      sx={{ position: "relative" }}
      onMouseEnter={(e) => {
        handleHover();
      }}
      onMouseLeave={(e) => {
        setIsHoverMenu(false);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: 1,
          cursor: "pointer",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: 16,
            fontFamily: "OakSans",
            color: isSticky ? "#052343" : "#ffffff",
            lineHeight: 0,
            fontWeight: 500,
            mt: 0,
            textUnderlineOffset: 5,
          }}
        >
          {menuName}
        </Typography>

        <ExpandMoreIcon
          sx={{
            color: isSticky ? "#052343" : "#ffffff",
            transform: isHoverMenu ? "rotate(180deg)" : 'none',
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Box>

      {isHoverMenu ? (
        <Box
          sx={{
            position: "absolute",
            top: 25,
            bgcolor: "#fff",
            p: 1,
            width: 150,
            borderRadius: 2,
            bgcolor: isSticky ? "#fff" : "#05234365",
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          }}
        >
          {_.map(list, (navItem, index) => {
            return (
              <Box key={index + 1} sx={{ pt: 0.5, pb: 0.5 }}>
                <Link
                  style={{
                    fontSize: 16,
                    fontFamily: "OakSans",
                    color: isSticky ? "#052343" : "#ffffff",
                    textDecoration: "none",
                    textUnderlineOffset: 5,
                    fontWeight: 500,
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.textDecoration = "underline";
                    e.target.style.textDecorationColor = "#FD5B2C";
                    e.target.style.textDecorationStyle = "solid";
                  }}
                  onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                  to={_.get(navItem, "path")}
                >
                  {_.get(navItem, "page")}
                </Link>
              </Box>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};
