import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { PlusCountComponent } from "../../../../components/atoms/PlusCount/PlusCountComponent";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";

export const AboutUsSection = () => {

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ paddingInline: 2 }}>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <HeadingTagComponent text="About Us" />
              <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                <Grid item>
                  <HeadingBlueComponent variant="h4" text="Why Choose" />
                </Grid>
                <Grid item>
                  <HeadingRedComponent variant="h4" text="OASES" />
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
                OASES Education (Pvt) Ltd, established in 2010 in Kurunegala,
                Sri Lanka, is a leading education consultancy focused on
                supporting students, learning professionals, and educational
                institutions. It aims to simplify studying and interning abroad
                through technology and knowledge consulting. OASES helps
                students select suitable courses and universities based on
                market trends, financial budgets, and other factors.
              </Typography>

              <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
                Our comprehensive services include application assistance,
                interview preparation, visa processing, and accommodation
                arrangements. OASES represents numerous universities worldwide,
                including in the USA, Canada, UK, Australia, and several Asian
                countries, guiding students from bachelor's degree programs to
                Ph.D. and pathway programs. Our counselors are trained to
                provide accurate, up-to-date information to students and
                parents, ensuring a successful education path abroad.
              </Typography>

              <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
                OASES Educational Services (Pvt) Ltd is delighted to announce
                its global expansion by establishing branch offices worldwide.
                These branches enable us to reach a broader audience and offer
                innovative learning options, ensuring that exceptional education
                is accessible to students around the globe.
              </Typography> 
            </Stack>
          </Stack>
        </Box>
      </Container>
      <Box>
        <Container maxWidth={"xl"}  sx={{ bgcolor: "#DCE9F1", mt: 2, pt: 3, pb: 3 }}>
          <Grid
            container
            sx={{
              paddingInline: 2,
              justifyContent: "space-between",
              rowGap: 2,
            }}
          >
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
                Empowering students for over a decade, OASES Educational
                Services Pvt Ltd has successfully guided numerous students to
                study abroad, partnering with leading universities worldwide.
              </Typography>
            </Grid>
            <Grid
              item
              xl={5}
              lg={5}
              md={5}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <PlusCountComponent count={1800} text="Students Abroad" />
              <PlusCountComponent count={15} text="Years in Business" />
              <PlusCountComponent count={150} text="Partners" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
