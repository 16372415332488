import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";

export const WhoWeAreSection = () => {
  return (
    <Container maxWidth={"xl"}>
      <Stack spacing={2} alignItems={"center"} sx={{ paddingInline: 2 }}>
        <HeadingTagComponent text="Who We Are" />
        <Stack alignItems={"center"}>
          <HeadingBlueComponent variant="h4" text="Our Journey in" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              columnGap: 1,
            }}
          >
            <HeadingRedComponent variant="h4" text="Education" />
            <HeadingBlueComponent variant="h4" text="Excellence" />
          </Box>
        </Stack>

        <Box sx={{ width: "100%" }}>
          <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
            {/* Since March 5, 2010, OASES has successfully helped thousands of Sri
            Lankan students secure placements in top universities worldwide.
            Their services span study destinations such as the USA, Canada,
            Australia, New Zealand, the UK, France, Latvia, Singapore, and
            Malaysia. OASES offers comprehensive study abroad consultancy,
            including customized consultations, university applications, SOP
            writing, visa processing, career guidance, airport pickup,
            accommodation placement, and more. Their flexible and supportive
            consultancy services enable local students to meet international
            standards. OASES is the trusted choice for students seeking higher
            education and professional qualifications from leading global
            institutions */}
            {/* Established on March 5, 2010, OASES Educational Services Pvt Ltd is
            one of Sri Lanka's best educational consultation services. With
            roots in Kurunegala and Colombo, OASES has expanded its global
            presence to include the United States, Canada, and Singapore. This
            extensive network enables OASES to provide superior educational
            consultancy and services, using worldwide ideas and possibilities
            that benefit both students and educators. */}
            OASES Education (Pvt) Ltd, founded in 2010 in Kurunegala, Sri Lanka,
            and unfolded as OASES Global in the USA, Canada, UK Singapore,
            Dubai, and Indonesia is a top education consultancy that simplifies
            studying and interning abroad through technology and consulting. We
            assist students in selecting courses and universities based on
            various factors and offer services like application help, interview
            preparation, visa processing, and accommodation arrangements.
            Representing universities globally, OASES guides students through
            bachelor's, Ph.D., and pathway programs with trained counselors
            providing accurate information.
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};
