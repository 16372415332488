import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { TextbuttonOnhoverUnderline } from "../../../../components/atoms/Buttons/TextbuttonOnhoverUnderline";

export const WhatWeDoSection = () => {
  return (
    <Container maxWidth={"lg"}>
      <Stack spacing={2} >
        <HeadingTagComponent text="What we do " colorMode="lite" />
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                rowGap: 1,
              }}
            >
              <Grid item xl={4} lg={4} md={5} sm={5} xs={12}>
                <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                  <Grid item>
                    <HeadingBlueComponent
                      variant="h4"
                      colorMode="lite"
                      text="Our"
                    />
                  </Grid>
                  <Grid item>
                    <HeadingRedComponent variant="h4" text="Services" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={8} lg={8} md={7} sm={7} xs={12}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontFamily: "OakSans" }}
                  >
                    OASES Education (Pvt) Ltd, established in 2010 in
                    Kurunegala, Sri Lanka, is a leading education consultancy
                    focused on supporting students, learning professionals, and
                    educational institutions. It aims to simplify the process of
                    studying and interning abroad through technology and
                    knowledge consulting. OASES helps students select suitable
                    courses and universities based on market trends, financial
                    budgets, and other factors. Their comprehensive services
                    include application assistance, interview preparation, visa
                    processing, and accommodation arrangements. OASES represents
                    numerous universities worldwide, including in the USA,
                    Canada, UK, Australia, and several Asian countries, guiding
                    students from bachelor's degree programs to Ph.D. and
                    pathway programs. Their counselors are trained to provide
                    accurate, up-to-date information to students and parents,
                    ensuring a successful education path abroad.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};
