import React from "react";
import { Typography } from "@mui/material";

export const HeadingBlueComponent = ({
  variant = "",
  text = "",
  colorMode = "lite",
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: "ClashGrotesk",
        color: colorMode == "lite" ? "#052343" : "#ffffff",
        fontWeight: 500,
      }}
    >
      {text}
    </Typography>
  );
};
