import React, { useState } from "react";
import {
  Typography,
  Divider,
  Grid,
  Box,
  Container,
  Avatar,
  Stack,
} from "@mui/material";
import {
  CAN_FLAG,
  CAN_FLAG_FRAME,
  FACEBOOK_ICON,
  INSTAGRAM_ICON,
  LINKEDIN_ICON,
  LOGO_WITH_TEXT,
  MAL_FLAG,
  MAL_FLAG_FRAME,
  SEND_FA_O_ICON,
  SL_FLAG,
  SL_FLAG_FRAME,
  TWITTER_ICON,
  US_FLAG,
  US_FLAG_FRAME,
} from "../../../assets/Images";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import _ from "lodash";
import { LazyLoadImageComponent } from "../../atoms/LazyLoad/LazyLoadImageComponent";

export const FooterContainer = () => {
  const [value, setValue] = useState("");
  const [selectedFlag, setSelectedFlag] = useState(0);

  const flags = [
    {
      name: "CAN",
      framedFlag: CAN_FLAG_FRAME,
      nonFramedFlag: CAN_FLAG,
      address: "117,Emerald Street South,Unit 11,Hamilton,8N 2V4, Ontario",
      address2: null,
      phoneNo: "+14379815877",
      country: "Canada",
    },
    {
      name: "SL",
      framedFlag: SL_FLAG_FRAME,
      nonFramedFlag: SL_FLAG,
      address: "No 101 A , 2nd Floor, Colombo Road, Kurunegala",
      address2: "No.57/1, 5th Lane, Colombo 3",
      phoneNo: "+94 (77) 225 7575",
      country: "Sri Lanka",
    },
    {
      name: "US",
      framedFlag: US_FLAG_FRAME,
      nonFramedFlag: US_FLAG,
      address: "OASES Global LLC, 1401 21st Street, STE R Sacramento, CA 95811",
      address2: null,
      phoneNo: "+1 (562) 336-7490",
      country: "USA",
    },
    { 
      name: "SIN",
      framedFlag: MAL_FLAG_FRAME,
      nonFramedFlag: MAL_FLAG,
      address:
        "OASES Global Pte Ltd, 68 Circular Road, #02-01, Singapore (049422)",
      address2: null,
      phoneNo: "+65 (9) 069 4654",
      country: "Singapore",
    },
  ];

  const onChangeEmail = (index) => {
    setValue(index.target.value);
  };

  const onClickSendEmail = () => {
    // console.log("send email");
  };

  const onFlagClick = (flagName, index) => {
    // console.log("selected flag: ", flagName, index);
    setSelectedFlag(index);
  };

  return (
    <Box sx={{ backgroundColor: "#01203D" }}>
      <Grid container sx={{ flexDirection: "column" }}>
        <Grid item>
          <Container maxWidth={"xl"}>
            <Grid
              container
              sx={{
                justifyContent: {
                  xl: "center",
                  lg: "center",
                  md: "center",
                  sm: "flex-start",
                  xs: "flex-start",
                },
                alignItems: "flex-start",
              }}
            >
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={9}
                sx={{
                  mt: 5,
                }}
              >
                <LazyLoadImageComponent
                  src={LOGO_WITH_TEXT}
                  alt="footer-logo"
                  styles={{
                    borderRadius: 0,
                    width: "70%",
                    height: "70%",
                    objectFit: "cover",
                  }}
                />

                <Box
                  sx={{
                    paddingInlineEnd: 2,
                    pt: 3,
                    pb: 4,
                    height: { xl: 310, lg: 280, md: 250 },
                  }}
                >
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    {_.map(flags, (flag, index) => {
                      return (
                        <Grid
                          item
                          md={2}
                          key={index + 1}
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            onFlagClick(_.get(flag, "name"), index)
                          }
                        >
                          <LazyLoadImageComponent
                            src={
                              selectedFlag == index
                                ? _.get(flag, "framedFlag")
                                : _.get(flag, "nonFramedFlag")
                            }
                            alt="flag"
                            styles={{
                              borderRadius: 0,
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        borderStyle: "solid",
                        borderColor: "#ffffff80",
                        borderWidth: 1,
                        height: 150,
                        p: 1,
                      }}
                    >
                      <Grid container sx={{ alignItems: "flex-start", gap: 1 }}>
                        <Grid
                          item
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{ color: "#ffffff" }}
                        >
                          <LocationOnIcon fontSize="small" />
                        </Grid>
                        <Grid item md={9} sm={9} xs={9}>
                          <Stack spacing={1}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontFamily: "ClashGrotesk",
                                fontWeight: 400,
                                color: "#ffffff",
                              }}
                            >
                              {_.get(flags[selectedFlag], "country")}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                fontFamily: "ClashGrotesk",
                                fontWeight: 400,
                                color: "#ffffff65",
                              }}
                            >
                              {_.get(flags[selectedFlag], "address")}
                            </Typography>

                            <Typography
                              variant="caption"
                              sx={{
                                fontFamily: "ClashGrotesk",
                                fontWeight: 400,
                                color: "#ffffff65",
                              }}
                            >
                              {_.get(flags[selectedFlag], "phoneNo")}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Divider
                sx={{
                  bgcolor: "#ffffff30",
                  display: {
                    xl: "block",
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                }}
                orientation="vertical"
                flexItem
              />
              <Grid
                item
                flexGrow={1}
                sx={{ pt: { xl: 6, lg: 6, md: 6, sm: 0, xs: 0 } }}
              >
                <Grid
                  container
                  sx={{ columnGap: 2, rowGap: 3, justifyContent: "center" }}
                >
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
                  >
                    <Typography
                      variant="h6"
                      color="white"
                      fontFamily={"sans-serif"}
                    >
                      Service
                    </Typography>
                    <ol className="footer-list">
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{ color: "white", fontFamily: "OakSans" }}
                            variant="caption"
                          >
                            Study Abroad Service
                          </Typography>
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "white",
                              cursor: "pointer",
                              fontFamily: "OakSans",
                            }}
                            variant="caption"
                          >
                            Study Destination
                          </Typography>
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "white",
                              cursor: "pointer",
                              fontFamily: "OakSans",
                            }}
                            variant="caption"
                          >
                            Find a course
                          </Typography>
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "white",
                              cursor: "pointer",
                              fontFamily: "OakSans",
                            }}
                            variant="caption"
                          >
                            IELTS
                          </Typography>
                        </Link>
                      </li>
                    </ol>
                  </Grid>

                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
                  >
                    <Typography
                      variant="h6"
                      color="white"
                      fontFamily={"sans-serif"}
                    >
                      Company
                    </Typography>
                    <ol className="footer-list">
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{ color: "white", fontFamily: "OakSans" }}
                            variant="caption"
                          >
                            About Us
                          </Typography>
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "white",
                              cursor: "pointer",
                              fontFamily: "OakSans",
                            }}
                            variant="caption"
                          >
                            News and blogs
                          </Typography>
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "white",
                              cursor: "pointer",
                              fontFamily: "OakSans",
                            }}
                            variant="caption"
                          >
                            Gallery
                          </Typography>
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }}>
                          <Typography
                            sx={{
                              color: "white",
                              cursor: "pointer",
                              fontFamily: "OakSans",
                            }}
                            variant="caption"
                          >
                            FAQs
                          </Typography>
                        </Link>
                      </li>
                    </ol>
                  </Grid>

                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={5}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 1,
                      pb: 5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="white"
                      fontFamily={"sans-serif"}
                    >
                      Letters
                    </Typography>
                    <Box
                      sx={{
                        width: 280,
                        display: "flex",
                        paddingInlineStart: 1,
                        flexDirection: "column",
                        rowGap: 2,
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="white"
                        fontFamily={"OakSans"}
                        sx={{ lineHeight: 2 }}
                      >
                        Stay informed about the latest news in global education
                        and receive exclusive tips and insights by subscribing
                        to our newsletter.
                      </Typography>

                      <div className="send-email-container">
                        <input
                          type="email"
                          placeholder="Enter your e-mail"
                          value={value}
                          onChange={(index) => onChangeEmail(index)}
                        />
                       
                        
                        <img
                          src={SEND_FA_O_ICON}
                          alt="send-icon"
                          onClick={() => onClickSendEmail()}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Divider sx={{ bgcolor: "#ffffff30" }} flexItem />
        <Grid item sx={{ bgcolor: "#052343" }}>
          <Container maxWidth={"xl"}>
            <Grid
              container
              justifyContent={"space-between"}
              pt={1}
              pb={1}
              sx={{
                justifyContent: "space-between",
                pt: 1,
                pb: 1,
                paddingInline: 2,
                rowGap: 2,
              }}
            >
              <Grid item sx={{ order: { xl: 1, lg: 1, md: 1, sm: 1, xs: 2 } }}>
                <Typography
                  variant="caption"
                  fontFamily={"OakSans"}
                  color="white"
                >
                  © 2024 OASES Pvt Ltd. All rights reserved.{" "}
                </Typography>
              </Grid>
              <Grid item sx={{ order: { xl: 2, lg: 2, md: 2, sm: 2, xs: 1 } }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <LazyLoadImageComponent
                    src={FACEBOOK_ICON}
                    alt="sm-icons"
                    styles={{
                      width: 30,
                      height: 30,
                    }}
                  />

                  <LazyLoadImageComponent
                    src={TWITTER_ICON}
                    alt="sm-icons"
                    styles={{
                      width: 30,
                      height: 30,
                    }}
                  />

                  <LazyLoadImageComponent
                    src={LINKEDIN_ICON}
                    alt="sm-icons"
                    styles={{
                      width: 30,
                      height: 30,
                    }}
                  />

                  <LazyLoadImageComponent
                    src={INSTAGRAM_ICON}
                    alt="sm-icons"
                    styles={{
                      width: 30,
                      height: 30,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};
