import React from "react";
import _ from "lodash";
import { Box, Container, Typography } from "@mui/material";
import { LazyLoadImageComponent } from "../../LazyLoad/LazyLoadImageComponent";

export const HeroSliderComponent = ({ imageList = [], HeroText = "" }) => {
  return (
    <Container maxWidth={"xl"} style={{ paddingRight: 0, paddingLeft: 0 }}>
      <swiper-container
        slides-per-view="1"
        speed="3000"
        loop="true"
        effect="fade"
        autoplay-delay="4000"
        autoplay-disable-on-interaction="false"
        effect-cube="true"
        style={{ height: "100svh", width: "100%" }}
      >
        {_.map(imageList, (image, index) => {
          return (
            <swiper-slide key={index + 1}>
              <Box
                sx={{
                  backgroundImage: `url(${_.get(image, "image", "")})`,
                  width: "100%",
                  height:'100svh',
                  position: "relative",
                }}
              >
                <LazyLoadImageComponent
                  src={_.get(image, "image", "")}
                  alt={`img${index}`}
                  styles={{
                    width: "100%",
                    height: 'auto',
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                ></LazyLoadImageComponent>
                <Box
                  sx={{
                    position: "absolute",
                    top: -35,
                    background:
                      "linear-gradient(to bottom, #00000050, #ffffff00)",
                    height: "100px",
                    width: "100%",
                  }}
                >
                  <Container>
                    <Box mt={22}>
                      <Typography
                        variant="h1"
                        sx={{
                          fontFamily: "OakSans",
                          textAlign: "center",
                          color: "#052343",
                        }}
                      >
                        Unlock Your{" "}
                        <span
                          style={{
                            fontFamily: "Tropiline",
                            fontStyle: "italic",
                            fontWeight: 700,
                            color: "#FD5B2C",
                            // paddingInline:10
                          }}
                        >
                          Future
                        </span>{" "}
                        Today
                      </Typography>
                    </Box>
                  </Container>
                </Box>
              </Box>
            </swiper-slide>
          );
        })}
      </swiper-container>
    </Container>
  );
};
