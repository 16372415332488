import React from "react";

export const ArrowIcon = ({ direction = "isForward", isSelected }) => {
  return (
    <svg
      width="29"
      height="19"
      viewBox="0 0 29 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10765e-07 9.28862L9.2864 8.21267e-05L10.7968 1.50835L4.0832 8.22195L28.1749 8.22195L28.1749 10.3553L4.0832 10.3553L10.7968 17.0667L9.2864 18.5771L1.10765e-07 9.28862Z"
        fill={isSelected ? "#ffffff" : "#1A2937"}
        transform={direction == "isForward" ? "scale(-1, 1) translate(-29, 0)" : ""}
      />
    </svg>
  );
};
