import React from "react";
import { Route, Routes } from "react-router-dom";
import { WebsiteLayout } from "../layouts";
import {
  AboutUsPage,
  ContactUs,
  Faqs,
  HomePage,
  NewsBlogs,
  PageNotFound,
  StudentServices,
} from "../modules";

export const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route element={<WebsiteLayout />}>
          <Route index element={<HomePage />} />
          <Route index path="/Home" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/student-services" element={<StudentServices />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/news-blogs" element={<NewsBlogs />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};
