import React from "react";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { HeadingBlueComponent } from "../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../components/atoms/Headings/HeadingRedComponent";
import { HeadingTagComponent } from "../../../components/atoms/Tags/HeadingTagComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import _ from "lodash";


export const GetInTouchSection = () => {
    const branches = [{contact_no1:'+94 (77) 225 7575 ', contact_no2:'+94 (77) 225 7575 ', address:'No: 101, 2nd Floor, Colombo Road, Kurunegala, Sri Lanka. 60000', email:'info@oasesedu.com'}]
  return (
    <Container maxWidth={"lg"}>
      <Stack spacing={2}>
        <Grid container sx={{ alignItems: "flex-start" }}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Stack spacing={2}>
              <HeadingTagComponent colorMode="lite" text={"Contact Us"} />
              <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                <Grid item>
                  <HeadingBlueComponent
                    variant="h4"
                    colorMode="lite"
                    text="Get In"
                  />
                </Grid>
                <Grid item>
                  <HeadingRedComponent variant="h4" text="Touch" />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography variant="subtitle1">
              We’re here to assist you. Whether you have questions, need
              guidance, or want to explore opportunities, feel free to contact
              us. Our team is ready to support you in your journey.
            </Typography>
          </Grid>
        </Grid>

        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true, el: ".custom-pagination" }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 50,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
              slidesPerGroup: 3,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 10,
              slidesPerGroup: 4,
            },
          }}
          className="custom-swiper"
        >
          {_.map(branches, (branch, index) => {
            return <SwiperSlide key={index + 1}></SwiperSlide>;
          })}
          <div className="custom-pagination" />
        </Swiper>
      </Stack>
    </Container>
  );
};
