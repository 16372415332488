import React from 'react';
import { Box, Stack } from '@mui/material'
import { NewsUpdateSection } from '../../components/organisms';
import {  STUDENT_SERVICES_MAIN } from '../../assets/Images';
import { WhatWeDoSection } from './Sections/WhatWeDoSection/WhatWeDoSection';
import { EndToEndGuidance } from './Sections/EndToEndGuidance/EndToEndGuidance';
import { ContactUsSection } from './Sections/ContactUsSection/ContactUsSection';
import { LazyLoadImageComponent } from '../../components/atoms/LazyLoad/LazyLoadImageComponent';

export const StudentServices = () => {
  return (
    <Box>
    <Stack spacing={10} mb={5}>
    <Box component={"section"}>
        <LazyLoadImageComponent src={STUDENT_SERVICES_MAIN} alt={'main-img-student-service'} styles={{ width: "100%", height: 'auto', objectFit: "cover"}}/>
      </Box>


      <Box component={"section"}>
      <WhatWeDoSection/>
      </Box>

      <Box component={'section'}>
        <EndToEndGuidance/>
      </Box>

     
      <Box component={'section'}>
        <ContactUsSection/>
      </Box>


      <Box component={"section"}>
        <NewsUpdateSection />
      </Box>
    </Stack>
  </Box>
  )
}
