import ApiInstance from "./ApiInstance";

const getPath = (path) => {
  return `contact-us/${path}`;
};
class ContactUs extends ApiInstance {
    createMessage = async (params) => {
        try {
          const data = await this.postData(getPath("message-create"), params);
          return data;
        } catch (error) {
          return error;
        }
      };
}

export default  new ContactUs();
