import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const LazyLoadImageComponent = ({
  src,
  alt,
  styles,
  onClick = () => {},
}) => {
  return (
    <LazyLoadImage
      onClick={onClick}
      alt={alt}
      className="gallery-img"
      effect="blur"
      src={src}
      width="100%"
      style={styles}
    />
  );
};
