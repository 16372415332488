import { colors, createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#052343",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      contrastText: "#fff",
    },
    secondary: {
      main: "#052343",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#052343    ",
    },
  },
  typography: {
    fontFamily: [
      "ClashGrotesk",
      "OakSans",
      "Tropiline",
      "sans-serif",
    ].join(","),
    h1: {
      fontFamily: "ClashGrotesk",
      fontWeight: 700,
      fontSize: "3rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
      "@media (min-width:960px)": {
        fontSize: "4rem",
      },
    },
    h2: {
      fontFamily: "OakSans",
      fontWeight: 600,
      fontSize: "1.5rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
      "@media (min-width:960px)": {
        fontSize: "2.5rem",
      },
    },
    body1: {
      fontFamily: "Tropiline",
      fontSize: "1rem",
      "@media (min-width:600px)": {
        fontSize: "1.125rem",
      },
      "@media (min-width:960px)": {
        fontSize: "1.25rem",
      },
    },
    body2: {
      fontFamily: "TropilineSans",
      fontWeight: 300,
      fontSize: "0.875rem",
      "@media (min-width:600px)": {
        fontSize: "1rem",
      },
      "@media (min-width:960px)": {
        fontSize: "1.125rem",
      },
    },
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});
