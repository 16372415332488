import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";

export const ProfessionalDevelopment = () => {
  return (
    <Container maxWidth={"xl"} sx={{ bgcolor: "#01203D", pt: 10, pb: 10 }}>
      <Container maxWidth={"sm"}>
        <Grid
          container
          sx={{ flexDirection: "column", alignItems: "center", gap: 3 }}
        >
          <Grid item>
            <HeadingTagComponent
              text="Professional Development"
              colorMode="dark"
            />
          </Grid>
          <Grid item>
            <Grid
              container
              sx={{ alignItems: "baseline", justifyContent: "center", gap: 1 }}
            >
              <Grid item>
                <HeadingBlueComponent
                  variant="h4"
                  colorMode="dark"
                  text="Ready To Join"
                />
              </Grid>
              <Grid item>
                <HeadingRedComponent variant="h4" text="Our Team ?" />
              </Grid>
            </Grid>
          </Grid>
          <Box className="professional-dev-email-container">
            <input type="email" placeholder="Email Address*" />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#ffffff95",
                columnGap: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{}}>
                Submit
              </Typography>
              <ArrowOutwardIcon sx={{ fontSize: "large" }} />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Container>
  );
};
