import { Box } from "@mui/material";
import React from "react";
import { RotatingSquare, ThreeCircles } from "react-loader-spinner";

export const LoaderComponent = () => {
  return (
    <Box sx={{  width: "40%", height: "40%",}}>
      <RotatingSquare
        visible={true}
        height="100%"
        width="100%"
        color="#FD5B2C"
        ariaLabel="rotating-square-loading"
        wrapperStyle={{width:'100%', height:'100%'}}
        wrapperClass=""
      />
    
    </Box>
  );
};
