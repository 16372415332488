import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { LazyLoadImageComponent } from "../../atoms/LazyLoad/LazyLoadImageComponent";

export const NewsCard = ({ news = {} }) => {
  const onClickCard = (link) => {
    window.open(link, "_blank");
    // window.location.href = link;
  };
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: 0,
        boxShadow: "none",
        cursor: "pointer",
      }}
      onClick={() => onClickCard(_.get(news, "link", ""))}
    >
      <LazyLoadImageComponent
        src={_.get(news, "image", "")}
        alt={`card-img-news`}
        styles={{ height: 140, objectFit: "cover", objectPosition: "center" }}
      />
      <CardContent sx={{ paddingInline: 0 }}>
        <Stack spacing={1}>
          <Box sx={{ display: "flex", alignContent: "flex-start", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                bgcolor: "#B7D1E1",
                p: 0.4,
                borderRadius: 0.5,
                width: "fit-content",
                paddingInline: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontSize: "0.8rem" }}>
                {_.get(news, "country", "")}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "OakSans",
              fontWeight: 500,
              color: "#01203D",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {_.get(news, "title", "")}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              fontFamily: "OakSans",
              // fontWeight: 300,
              color: "#01203D",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {_.get(news, "description", "")}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
