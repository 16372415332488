import React from "react";
import { Box, Typography } from "@mui/material";

export const HeadingTagComponent = ({ text = "", colorMode = "lite" }) => {
  return (
    <Box
      sx={{
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 5,
        borderColor: colorMode == "lite" ? "#B7D1E1" : "#ffffff",
        pl: 2,
        pr: 2,
        display: "flex",
        width: "fit-content",
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontFamily: "OakSans",
          fontWeight: 600,
          color: colorMode == "lite" ? "#052343" : "#ffffff",
          lineHeight:2,
          
          
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
