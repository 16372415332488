import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const PlusCountComponent = ({
  count = 400,
  text = "Students Abroad",
}) => {
  const countVal = useMotionValue(0); 
  const rounded = useTransform(countVal, (latest) => Math.round(latest));

  // Intersection Observer
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.1, 
  });

  useEffect(() => {
    if (inView) {
      const controls = animate(countVal, count, {
        duration: 3, 
        ease: "easeOut",
      });
      return () => controls.stop();
    }
  }, [inView, count, countVal]);

  return (
    <Stack alignItems={"center"}>
      <Box ref={ref} sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
        <Typography
          variant="h2"
          sx={{ fontFamily: "sans-serif", fontWeight: 600, color: "#052343" }}
        >
          <motion.div>{rounded}</motion.div>
        </Typography>
        <Typography
          variant="h2"
          sx={{ fontFamily: "sans-serif", fontWeight: 600, color: "#FD5B2C" }}
        >
          +
        </Typography>
      </Box>
      <Typography
        variant="subtitle2"
        sx={{ fontFamily: "OakSans !important", textAlign: "center", fontWeight:400 }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

