import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ApplicationRoutes } from "./routes/ApplicationRoutes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
import { NavbarProvider } from "./core/context/NavbarContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavbarProvider>
        <BrowserRouter>
          <ApplicationRoutes />
        </BrowserRouter>
      </NavbarProvider>
    </ThemeProvider>
  );
}

export default App;
