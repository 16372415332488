import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import { TextbuttonOnhoverUnderline } from "../../../atoms/Buttons/TextbuttonOnhoverUnderline";
import { NewsCard } from "../../../molecules";
import _ from "lodash";
import {  NEWS_CAN, NEWS_UK, NEWS_USA } from "../../../../assets/Images";
import NewsUpdatesApis from "../../../../core/services/NewsUpdatesApis";

export const NewsUpdateSection = () => {
  const isMediumScreen = () => window.matchMedia("(max-width: 1386px)").matches;
  const isSmallScreen = () => window.matchMedia("(max-width: 768px)").matches;
  const [isMd, setIsMd] = useState(isMediumScreen());
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    getAllNewsUpdates();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMd(isMediumScreen());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getAllNewsUpdates = async () => {
    const canadaNews = await NewsUpdatesApis.getCanadaNews();
    const ukNews = await NewsUpdatesApis.getUkNews();
    const usaNews = await NewsUpdatesApis.getUsaNews();

    if (
      _.get(canadaNews, "status") == 200 &&
      _.get(ukNews, "status") == 200 &&
      _.get(usaNews, "status") == 200
    ) {
      let canadaMapped = _.map(
        _.get(canadaNews, "data").splice(0, 4),
        (item) => {
          return {
            title: _.get(item, "title"),
            description: _.get(item, "description"),
            link: _.get(item, "link"),
            country: "Canada",
            image: NEWS_CAN,
          };
        }
      );
      let ukMapped = _.map(_.get(ukNews, "data").splice(0, 4), (item) => {
        return {
          title: _.get(item, "title"),
          description: _.get(item, "summary"),
          link: _.get(item, "link.@attributes.href"),
          country: "UK",
          image: NEWS_UK,
        };
      });
      let usaMapped = _.map(_.get(usaNews, "data").splice(0, 4), (item) => {
        return {
          title: _.get(item, "title"),
          description: _.get(item, "description[0]"),
          link: _.get(item, "link"),
          country: "USA",
          image: NEWS_USA,
        };
      });

      let combinedArray = [...ukMapped, ...canadaMapped, ...usaMapped];
      combinedArray.sort(() => Math.random() - 0.5);
      let finalArray = combinedArray.slice(0, 4);
      setNewsList(finalArray);
    }
  };

  return (
    <Container maxWidth={"lg"}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <HeadingTagComponent text="News And Blogs" colorMode="lite" />
          <Grid
            container
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item>
              <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                <Grid item>
                  <HeadingBlueComponent
                    variant="h4"
                    colorMode="lite"
                    text="Latest"
                  />
                </Grid>
                <Grid item>
                  <HeadingRedComponent variant="h4" text="News" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextbuttonOnhoverUnderline text="Read all articles" />
            </Grid>
          </Grid>
        </Stack>

        <Grid container spacing={2} sx={{ rowGap: 3 }}>
          {_.map(newsList, (news, index) => {
            return (
              <Fragment key={index + 1}>
                <Grid item xl={2.85} lg={2.85} md={2.8} sm={5.5} xs={11}>
                  <NewsCard news={news} />
                </Grid>
                <Grid item>
                  {index < newsList?.length - 1 ? (
                    <Divider
                      orientation="vertical"
                      sx={{
                        bgcolor: "#B7D1E1",
                        display: {
                          xl: "block",
                          lg: "block",
                          md: "block",
                          sm: "none",
                          xs: "none",
                        },
                      }}
                      // flexItem
                    />
                  ) : null}
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};
