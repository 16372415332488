import React from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { PAGE_NOT_FOUNT_0 } from "../../assets/Images";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth={"xl"} sx={{bgcolor:'#E9EFF5'}}>
      <Box
        component={"section"}
        sx={{
          height: "100svh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <Box className="middle">
            <img
              src={PAGE_NOT_FOUNT_0}
              alt="404"
              className="page-not-found-img"
            />
          </Box>
          <div>
            <h1 className="page-not-found-text"> Sorry! </h1>
          </div>
          <span className="page-not-found-description">
            The page you are looking for was removed, renamed or not existed.!
          </span>

          <Button
            variant="outlined"
            sx={{
              width: 200,
              textTransform: "initial",
              borderRadius: 5,
              mt: 1,
            }}
            onClick={() => navigate("/home")}
          >
            Back to Homepage
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};
