import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ABOUT_US_MAIN,
  DREAM_ABROAD,
  NEWS_CAN,
  NEWS_UK,
  NEWS_USA,
} from "../../assets/Images";
import { HeadingTagComponent } from "../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../components/atoms/Headings/HeadingRedComponent";
import _ from "lodash";
import { NewsCard } from "../../components/molecules";
import NewsUpdatesApis from "../../core/services/NewsUpdatesApis";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { LoaderComponent } from "../../components/atoms/Loader/LoaderComponent";
import { LazyLoadImageComponent } from "../../components/atoms/LazyLoad/LazyLoadImageComponent";
import Pagination from "@mui/material/Pagination";
import { StackedLineChartOutlined } from "@mui/icons-material";
export const NewsBlogs = () => {
  const [newsList, setNewsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const selections = [
    {
      continent: "Europe",
      countries: [
        { country: "United Kingdom", value: "united_kingdom" },
        { country: "Norway", value: "norway" },
      ],
      value: "europe",
    },
    {
      continent: "North America",
      countries: [
        { country: "Canada", value: "canada" },
        { country: "United State", value: "united_state" },
      ],
      value: "north_america",
    },
    {
      continent: "Asia",
      countries: [{ country: "Singapore", value: "singapore" }],
      value: "asia",
    },
    {
      continent: "Oceania",
      countries: [
        { country: "Australia", value: "australia" },
        { country: "New Zealand", value: "new_zealand" },
      ],
      value: "oceania",
    },
  ];

  useEffect(() => {
    setLoader(true);
    getUkNews();
  }, []);

  const getCanadaNews = async () => {
    const canadaNews = await NewsUpdatesApis.getCanadaNews();
    if (_.get(canadaNews, "status") == 200) {
      let canadaMapped = _.map(_.get(canadaNews, "data"), (item) => {
        return {
          title: _.get(item, "title"),
          description: _.get(item, "description"),
          link: _.get(item, "link"),
          country: "Canada",
          image: NEWS_CAN,
        };
      });
      setLoader(false);
      setNewsList(canadaMapped);
    }
  };

  const getUkNews = async () => {
    const ukNews = await NewsUpdatesApis.getUkNews();
    if (_.get(ukNews, "status") == 200) {
      let ukMapped = _.map(_.get(ukNews, "data").splice(0, 4), (item) => {
        return {
          title: _.get(item, "title"),
          description: _.get(item, "summary"),
          link: _.get(item, "link.@attributes.href"),
          country: "UK",
          image: NEWS_UK,
        };
      });
      setLoader(false);
      setNewsList(ukMapped);
    }
  };

  const getUsaNews = async () => {
    const usaNews = await NewsUpdatesApis.getUsaNews();
    if (_.get(usaNews, "status") == 200) {
      let usaMapped = _.map(_.get(usaNews, "data"), (item) => {
        return {
          title: _.get(item, "title"),
          description: _.get(item, "description[0]"),
          link: _.get(item, "link"),
          country: "USA",
          image: NEWS_USA,
        };
      });
      setLoader(false);
      setNewsList(usaMapped);
    }
  };
  const onClickCountry = (value) => {
    switch (value) {
      case "united_kingdom":
        setLoader(true);
        getUkNews();

        break;
      case "canada":
        setLoader(true);
        getCanadaNews();
        break;
      case "united_state":
        setLoader(true);
        getUsaNews();
      default: {
      }
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      {loader ? (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 20,
            width: "100%",
            height: "100%",
          }}
        >
          <LoaderComponent />
        </Box>
      ) : null}
      <Stack spacing={10} mb={5}>
        <Box component={"section"}>
          <LazyLoadImageComponent
            src={ABOUT_US_MAIN}
            alt={"main-img-about-us"}
            styles={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </Box>
        <Container maxWidth={"xl"} component={"section"}>
          <Box component={"section"}>
            <Stack spacing={2}>
              <HeadingTagComponent text="News And Blog's" colorMode="lite" />
              <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                <Grid item>
                  <HeadingBlueComponent
                    variant="h4"
                    colorMode="lite"
                    text="Latest"
                  />
                </Grid>
                <Grid item>
                  <HeadingRedComponent variant="h4" text="News" />
                </Grid>
              </Grid>
            </Stack>
          </Box>

          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Stack spacing={2}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "ClashGrotesk",
                    color: "#FD5B2C",
                    fontWeight: 500,
                  }}
                >
                  Select a country
                </Typography>
                <div>
                  {_.map(selections, (item, index) => {
                    return (
                      <Accordion key={index + 1}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{
                            fontFamily: "ClashGrotesk",
                            fontWeight: 500,
                            fontSize: 16,
                          }}
                        >
                          {_.get(item, "continent", "")}
                        </AccordionSummary>
                        <Divider sx={{ bgcolor: "#01203D85" }} />
                        {_.map(
                          _.get(item, "countries"),
                          (countryData, index) => {
                            return (
                              <Box
                                sx={{ pt: 1, pb: 1 }}
                                key={index + 1}
                                onClick={() =>
                                  onClickCountry(_.get(countryData, "value"))
                                }
                              >
                                <Box sx={{ paddingInline: 3 }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontFamily: "OakSans",
                                      p: 1,

                                      cursor: "pointer",
                                      ":hover": { bgcolor: "#E9EFEC" },
                                    }}
                                  >
                                    {_.get(countryData, "country")}
                                  </Typography>
                                  <Divider sx={{ bgcolor: "#01203D10" }} />
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      </Accordion>
                    );
                  })}
                </div>
              </Stack>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
              <Grid container spacing={1}>
                {_.map(newsList, (item, index) => {
                  return (
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={index + 1}
                    >
                      <NewsCard news={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item sx={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
              <Pagination
                count={10}
                variant="outlined"
                sx={{
                  ".MuiPagination-ul": {
                    flexDirection: "row",
                  },
              
                }}
                shape="rounded"
              />
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </Box>
  );
};
