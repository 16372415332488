import React from "react";
import { Avatar, Card, CardContent, Stack, Typography } from "@mui/material";
import { LazyLoadImageComponent } from "../../atoms/LazyLoad/LazyLoadImageComponent";

export const CertificateCard = ({ certificate = "", text = "" }) => {
  return (
    <Card sx={{ width: "100%", bgcolor: "#ffffff00", boxShadow: "none" }}>
      <Stack spacing={2}>
        <LazyLoadImageComponent
          src={certificate}
          alt={"certificate"}
          styles={{ width: "100%", height: "100%" }}
        />
        <Typography
          variant="subtitle1"
          sx={{ color: "#ffffff", textAlign: "center" }}
        >
          {text}
        </Typography>
      </Stack>
    </Card>
  );
};
