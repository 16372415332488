import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";


export const TextbuttonOnhoverUnderline = ({
  text = "",
  
}) => {
  const navigate = useNavigate()
  return (
    <Box className="underline-onhover-text-btn" onClick={()=>navigate("/news-blogs")}>
      <Typography variant="subtitle1" sx={{ fontFamily: "OakSans" }}>
        {text}
      </Typography>
      <ArrowRightAltIcon />
    </Box>
  );
};
