import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { CALL_CENTER_ICON, VISION_IMG } from "../../../../assets/Images";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import _ from "lodash";

export const BeyondExpectationSection = () => {
  const [expanded, setExpanded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [onHover, setOnHover] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);

  const tabsContent = [
    {
      tabName: "Reliability",
      description:
        "OASES' experienced and reliable counselors guide students in evaluating their academic strengths, financial stability, and career plans to meet college admission and visa requirements.",
      icon: VISION_IMG,
    },
    {
      tabName: "Excelled in Visa Handling",
      description:
        "Over the past decade, OASES has achieved a high visa success rate for countries like France, the UK, and the US. Their experienced counselors guide students through the visa process, assist with documentation, reserve appointments, and support obtaining dependent visas if needed.",
      icon: VISION_IMG,
    },
    {
      tabName: "Delivering excellent pre-service and after-services",
      description:
        "OASES stands out by offering both excellent pre-services and unparalleled after-services. Unlike most consultants, they assist with airport pickup, finding accommodation, and, whenever possible, helping students secure part-time job placements.",
      icon: VISION_IMG,
    },
    {
      tabName: "Addressing Individual Requirements",
      description:
        "OASES, established in 2010, is a top-rated study-abroad counselor in Sri Lanka. They provide individualized support with professional, friendly, and dedicated counselors who offer one-on-one sessions from the first day of the college application process.",
      icon: VISION_IMG,
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onClickTab = (value) => {
    setTabIndex(value);
  };

  const handleOnhover = (index) => {
    setHoverIndex(index);
    setOnHover(true);
  };

  const handleOuthover = (index) => {
    setHoverIndex(index);
    setOnHover(false);
  };
  return (
    <Stack spacing={3}>
      <Container maxWidth={"xl"}>
        <Stack spacing={4} sx={{ paddingInline: 2 }}>
          <Stack spacing={4}>
            <HeadingTagComponent text="Beyond Expectations" />
            <Stack>
              <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                <Grid item>
                  <HeadingBlueComponent variant="h4" text="Excellence In" />
                </Grid>
              </Grid>
              <HeadingRedComponent variant="h4" text="Student Services" />
            </Stack>
          </Stack>
        </Stack>
      </Container>

      <Grid container sx={{ width: "100%" }}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ display: "flex", alignItems: "flex-start" }}
        >
          <Container maxWidth={"xl"}>
            <Box sx={{ paddingInline: 2 }}>
              <Stack
                spacing={3}
                sx={{
                  display: {
                    xl: "block",
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                {_.map(tabsContent, (tabData, index) => {
                  return (
                    <Box
                      key={index + 1}
                      sx={{ cursor: "pointer" }}
                      onClick={() => onClickTab(index)}
                      onMouseOver={() => handleOnhover(index)}
                      onMouseOut={() => handleOuthover(index)}
                    >
                      <Box
                        sx={{
                          paddingInline: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontFamily: "OakSans",
                            color: "#01203D",
                            fontWeight: 600,
                          }}
                        >
                          {_.get(tabData, "tabName", "")}
                        </Typography>

                        <Box sx={{ height: 5 }}>
                          {hoverIndex == index && onHover ? (
                            <ArrowOutwardIcon />
                          ) : null}
                        </Box>
                      </Box>
                      <Divider sx={{ bgcolor: "#000000" }} />
                    </Box>
                  );
                })}
              </Stack>

              <Box
                sx={{
                  display: {
                    xl: "none",
                    lg: "none",
                    md: "none",
                    sm: "block",
                    xs: "block",
                  },
                }}
              >
                {_.map(tabsContent, (tabData, index) => {
                  return (
                    <Accordion
                      key={index + 1}
                      defaultExpanded
                      sx={{ boxShadow: "none" }}
                      expanded={expanded === `panel${index + 1}`}
                      onChange={handleChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowOutwardIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, fontFamily: "OakSans" }}
                        >
                          {_.get(tabData, "tabName", "")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontFamily: "OakSans", color: "#01203D" }}
                        >
                          {_.get(tabData, "description", "")}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Box>
          </Container>
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            bgcolor: "#D8E6EF",
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Container maxWidth={"xl"}>
            <Box sx={{ paddingInline: 1, pt: 4, pb: 4 }}>
              <Stack spacing={2}>
                <Avatar
                  // src={_.get(tabsContent[tabIndex], "image", "")}
                  src={CALL_CENTER_ICON}
                  alt="image"
                  sx={{ width: 40, height: 40, borderRadius: 0 }}
                />
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontFamily: "OakSans", color: "#01203D" }}
                  >
                    {_.get(tabsContent[tabIndex], "description", "")}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Stack>
  );
};
