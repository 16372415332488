export const NavbarStyles = {
    toolbarDashboardDefault : {
        backgroundColor: "#4D4D4D77",
        borderRadius: 10,
        paddingInline: "44px !important",
        outlineStyle: "outset",
        outlineWidth: 1,
        outlineColor: "#ffffff40",
        backdropFilter: "100px",
    },
    toolbarDashboardScrolled : {
        backgroundColor: "#ffffff",
       
    },

}