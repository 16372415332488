import React, { createContext, useContext, useState } from "react";

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [isHome, setIsHome] = useState(true);

  const getCurrentPath = (path) => {
    if (path === "/" || path === "/home") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  };

  return (
    <NavbarContext.Provider value={{ isHome, getCurrentPath }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => useContext(NavbarContext);
