import React from "react";
import { Typography } from "@mui/material";

export const HeadingRedComponent = ({
  variant = "",
  text = "",
  fontStyle = "normal",
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: "Tropiline",
        fontStyle: fontStyle,
        color: "#FD5B2C",
        fontWeight: 700,
      }}
    >
      {text}
    </Typography>
  );
};
