import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const BranchDetailsContentComponent = ({
  country,
  address,
  contact,
  email,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "ClashGrotesk",
            fontWeight: 500,
            color: "#01203D",
          }}
        >
          {country}
        </Typography>
        <Stack>
          <Typography
            variant="subtitle2"
            sx={{ fontFamily: "OakSans", color: "#5D5D5D" }}
          >
            {address}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontFamily: "OakSans", color: "#5D5D5D" }}
          >
            {email}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ fontFamily: "OakSans", color: "#5D5D5D" }}
          >
            {contact}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
