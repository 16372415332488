import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import { ArrowIcon } from "../../../atoms/SvgIcons/ArrowIcon";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import "./StudyDestinationStyle.scss";
import {
  LOCATION_AUS,
  LOCATION_CAN,
  LOCATION_ENG,
  LOCATION_GRM,
  LOCATION_IRE,
  LOCATION_JAP,
  LOCATION_LAT,
  LOCATION_SING,
} from "../../../../assets/Images";
import { LazyLoadImageComponent } from "../../../atoms/LazyLoad/LazyLoadImageComponent";

export const StudyDestinations = () => {
  const [hoverFront, setHoverFront] = useState(false);
  const [HoverBack, setHoverBack] = useState(false);
  const studyLocations = [
    { link: "#", image: LOCATION_AUS, title: "Australia" },
    { link: "#", image: LOCATION_CAN, title: "Canada" },
    { link: "#", image: LOCATION_ENG, title: "England" },
    { link: "#", image: LOCATION_SING, title: "Singapore" },
    { link: "#", image: LOCATION_LAT, title: "Latvia" },
    { link: "#", image: LOCATION_JAP, title: "Japan" },
    { link: "#", image: LOCATION_GRM, title: "Germany" },
    { link: "#", image: LOCATION_IRE, title: "Ireland" },
  ];

  return (
    <Container maxWidth={"xl"}>
      <Grid container sx={{ rowGap: 2 }}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Container maxWidth="xl">
            <Stack spacing={4}>
              <HeadingTagComponent colorMode="lite" text="Study Destinations" />
              <Box>
                <HeadingBlueComponent
                  colorMode="lite"
                  variant="h4"
                  text="Our Study Abroad"
                />
                <HeadingRedComponent variant="h4" text="Destinations" />
              </Box>

              <Typography variant="subtitle1">
                Learn about our offices in various countries dedicated to
                providing exceptional educational consultation services.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <div
                  id="custom-prev"
                  className="nav-btn"
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => setHoverBack(true)}
                  onMouseOut={() => setHoverBack(false)}
                >
                  <ArrowIcon direction="back" isSelected={HoverBack} />
                </div>
                <div
                  id="custom-next"
                  className="nav-btn"
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => setHoverFront(true)}
                  onMouseOut={() => setHoverFront(false)}
                >
                  <ArrowIcon isSelected={hoverFront} />
                </div>
              </Box>
            </Stack>
          </Container>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Box
            sx={{
              width: "100%",
              paddingInlineStart: { xl: 0, lg: 0, md: 0, sm: 2, xs: 3 },
              paddingInlineEnd: { xl: 0, lg: 0, md: 0, sm: 0, xs: 3 },
            }}
          >
            <Swiper
              slidesPerView={3.5}
              centeredSlides={false}
              spaceBetween={20}
              loop={true}
              navigation={{
                prevEl: "#custom-prev",
                nextEl: "#custom-next",
              }}
              autoplay={{
                delay: 2000, // Delay between slides in milliseconds
                disableOnInteraction: false, // Keeps autoplay active after manual navigation
              }}
              modules={[Navigation]}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                425: {
                  slidesPerView: 1,
                  spaceBetween: 25,
                },
                640: {
                  slidesPerView: 3.5,
                  spaceBetween: 25,
                },

                768: {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },

                1024: {
                  slidesPerView: 2.8,
                  spaceBetween: 25,
                },

                1440: {
                  slidesPerView: 3.5,
                  spaceBetween: 25,
                },
              }}
            >
              {_.map(studyLocations, (location, index) => {
                return (
                  <SwiperSlide key={index + 1}>
                    <Card sx={{ borderRadius: 0, width: "100%", height: 330 }}>
                      <Box sx={{ position: "relative" }}>
                        <LazyLoadImageComponent
                          src={_.get(location, "image", "")}
                          alt={`destination-img${index + 1}`}
                          styles={{
                            objectFit: "cover",
                            height: 330,
                            width: "100%",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          width: "100%",
                          overflow: "hidden",
                          height: "65%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                          transition: ".6s ease",
                          background: {
                            xl: "none",
                            lg: "none",
                            sm: "linear-gradient(to top, #00000095, #ffffff00)",
                            xs: "linear-gradient(to top, #00000095, #ffffff00)",
                          },
                          pb: 2,
                          ":hover": {
                            background:
                              "linear-gradient(to top, #00000095, #ffffff00)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            pb: 0,
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#ffffff",
                            textAlign: "center",
                            fontWeight: 500,
                          }}
                        >
                          {_.get(location, "title")}
                        </Typography>
                      </Box>
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
