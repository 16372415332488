const setUserDetails = (token, userRole, userId) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user_role", userRole);
  localStorage.setItem("user_id", userId);
};

const getToken = () => {
  return localStorage.getItem("token");
};

const getUserRole = () => {
  return localStorage.getItem("user_role");
};

const getUserId = () => {
  return localStorage.getItem("user_id");
};

const clearUserInstance = () => {
  return localStorage.clear();
};

const UTIL_METHODS = {
  setUserDetails,
  getToken,
  getUserId,
  getUserRole,
  clearUserInstance,
};
export default UTIL_METHODS;
